import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
  { path: '', redirectTo: 'assets', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./core/components/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./core/components/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: 'assets',
    loadChildren: () =>
      import('./features/components/asset-list/asset-list.module').then(m => m.AssetListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'assets/:id',
    loadChildren: () =>
      import('./features/components/asset-management/asset-management.module').then(
        m => m.AssetManagementModule
      ),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'collections',
    loadChildren: () =>
      import('./features/components/tag-list/tag-list.module').then(m => m.TagListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'collections/:id',
    loadChildren: () =>
      import('./features/components/tag-management/tag-management.module').then(
        m => m.TagManagementModule
      ),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./features/components/template-list/template-list.module').then(
        m => m.TemplateListModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'templates/:id',
    loadChildren: () =>
      import('./features/components/template-management/template-management.module').then(
        m => m.TemplateManagementModule
      ),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'model-viewer',
    loadChildren: () =>
      import('./features/components/viewer/viewer.module').then(m => m.ViewerModule)
  },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class ResellerInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const clone = request.clone({
      headers: request.headers.set(
        'Linkroom-Reseller',
        'ad1401fcce5df1fc12be071e9efe149505323b20e8cf9493e94150096e4549ad'
      )
    });

    return next.handle(clone);
  }
}

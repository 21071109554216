<div id="components-gallery">
  <div class="row-gallery">
    <div class="row-head">
      <div class="column-sitetitle" [routerLink]="['/']" >
        <img [src]="organization.logo" alt="Linkroom" *ngIf="organization"/>
        <img src="/assets/images/logo.svg" alt="Linkroom" *ngIf="!organization"/>
      </div>

      <div class="row-button">
        <div class="components-lang-gallery">
          <!-- <span>{{ 'common.languages' | translate }}</span> -->
          <select [(ngModel)]="currentLanguage" (ngModelChange)="onChange($event)">
            <option *ngFor="let item of languages" [value]="item.value">{{ item.label }}</option>
          </select>
        </div>

        <div class="column-menu">
          <a
            *ngFor="let menu of menus"
            [ngClass]="{ active: onActive(menu) }"
            [routerLink]="['/', menu]"
            style="cursor: pointer"
          >
            {{ 'common.' + menu | translate }}
          </a>
        </div>

        <div class="column-username">
          <a style="cursor: pointer" (click)="toggleMenu()">
            <img src="/assets/images/icon-account.svg" alt="Linkroom" /><i></i>
          </a>
        </div>

        <div *ngIf="showMenu" class="menu">
          <a *ngIf="profile">{{ profile.email }}</a>
          <a *ngIf="organization">{{ organization.name }}</a>
        </div>
      </div>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>

<!-- <div id="components-loading">
  <div>
    <img src="assets/images/icon-loading.gif" alt="" />
    <span>Loading...</span>
  </div>
</div> -->

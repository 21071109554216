import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RoleViewModel } from '../models/role.model';
import { Result } from '@shared/models/result.model';
import { ENDPOINTS } from '@shared/util/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private http: HttpClient) {}

  get(id: string) {
    return this.http.get<Result<RoleViewModel | undefined>>(
      `${environment.api}${ENDPOINTS.roles}/${id}`
    );
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { clearLocalStorage } from '@shared/util/functions';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;
    if (!token) return next.handle(request);

    const clone = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });

    return next.handle(clone).pipe(
      tap({
        error: error => {
          if (error.status === 401) {
            clearLocalStorage();
            document.location.href = this.authService.redirectUrl || environment.admin;
          }
        }
      })
    );
  }
}

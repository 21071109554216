import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.role === 'write') return true;

    const path = route.routeConfig?.path;

    if (path && path.includes('/:id')) {
      const newPath = path.replace('/:id', '');
      this.router.navigate([newPath]);
    }

    return false;
  }
}
